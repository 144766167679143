.form {
  // form > form-error-icon
  .form-error-icon {
    @extend .d-none;
  }

  // form > form-group
  .form-group {
    margin: 0 0 $form-group-margin-bottom 0;
  }

  // form > form-control-label
  .form-control-label {
    margin: 0 0 $label-margin-bottom 0;
    color: $body-color;
  }

  // form > form-control
  .form-control {
    padding: $input-padding-y $input-padding-x;
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;

    font-family: $input-font-family;
    font-size: $input-font-size;
    font-style: $font-style-base;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;

    &.is-invalid {
      border: $input-border-width solid $danger;
    }
  }

  .g-recaptcha {
    margin: 0 0 $form-group-margin-bottom 0;
  }

  .reCAPTCHA-text {
    margin: 0 0 $form-group-margin-bottom 0;
    font-size: $font-size-base * .75;
    color: $body-color;
  }

  .form-success-message  {
    @extend .alert;
    @extend .alert-warning;
  }
}
