.alert {
  margin: 0 0 1rem 0;
  box-shadow: none;

  .form-error-icon {
    @extend .d-none;
  }
}

.alert-danger {
  background: $white;
  border: 1px solid $danger;
  color: $danger;
}
