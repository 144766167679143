.faq-overview {
  .faq-icon {
    @extend .position-absolute;
    top: 0;
    right: unset;
    bottom: unset;
    left: 0;
    z-index: 10;

    @extend .d-block;
    width: 50px;

    font-size: $font-size-base;
    line-height: 41px;
    @extend .text-center;
    color: $primary;
    transition: $transition-base;
  }

  .faq-category {
    margin: 15px 0 0 0;

    .faq-title {
      @extend .position-relative;
      margin: 0;
      padding: 10px 0 10px 50px;

      background: transparent;
      cursor: pointer;

      @include media-breakpoint-up(sm) {
        padding: 10px 10px 10px 50px;
      }

      h2 {
        margin: 0;

        font-family: $headings-font-family;
        font-size: $h4-font-size;
        font-style: $headings-font-style;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        text-transform: none;
        text-decoration: none;
        color: $body-color;
      }

      .faq-icon {
        line-height: 48px;
      }

      &:hover {
        .faq-icon {
          color: darken($primary, 15%);
        }
      }
    }

    .faq-items {
      display: none;

      .faq-item {
        .faq-question {
          @extend .position-relative;
          margin: 0 0 5px 0;
          padding: 10px 10px 10px 50px;
          background: transparent;
          border: 1px solid $gray-200;
          cursor: pointer;

          h3 {
            margin: 0;

            font-family: $headings-font-family;
            font-size: $font-size-base;
            font-style: $headings-font-style;
            font-weight: $headings-font-weight;
            line-height: $headings-line-height;
            text-transform: none;
            text-decoration: none;
            color: $body-color;
          }

          &:hover {
            .faq-icon {
              color: darken($primary, 15%);
            }
          }
        }

        .faq-answer {
          display: none;
          margin: 0 0 25px 0;
          padding: 10px 10px 10px 50px;

          background: transparent;
          border: 1px solid $gray-200;

          font-family: $font-family-base;
          font-size: $font-size-base;
          font-style: $font-style-base;
          font-weight: $font-weight-base;
          line-height: $line-height-base;
          text-transform: none;
          text-decoration: none;
          color: $body-color;
        }

        &.active {
          .faq-icon {
            i {
              transform: rotate(180deg);
            }
          }
        }

        .faq-icon {
          i {
            position: relative;
            transition: all 200ms;
          }
        }
      }
    }

    &.js-single-category {
      .faq-items {
        display: block;
      }
    }
  }
}
