.footer-list-wrapper {
    text-align: left !important;
    display: flex;
    flex-wrap: wrap;

    .footer-list-item {
        margin-top: 30px;

        // left side
        &:nth-child(odd) {
			@include media-breakpoint-up(lg) {
				@include make-col(5);
			}
        }

        // right side
        &:nth-child(even) {
			@include media-breakpoint-up(lg) {
				@include make-col(7);
			}
        }
    }

    h5 {
        font-size: 26px;
    }

    a {
        color: $black;
        text-decoration: none;
        font-size: 1rem;
        &:hover {
            color: $primary;
        }
    }

    p {
        font-size: 1rem;
    }
}