// custom-list
ul.custom-list {
    @extend .list-unstyled;
    li {
        position: relative;
        min-height: 26px;
        padding: 0 0 0 50px;
		line-height: 1.5;
        // max-width: 90%;
        &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            display: block;
            width: 26px;
            height: 20px;
            background-image: url('/images/check.svg');
            background-size: 100%;
            background-repeat: no-repeat;
        }
        + li {
            margin-top: 15px;
        }

        a {
            color: $black;

            &:hover {
                color: $link-hover-color;
            }
        }
    }
}

// custom-list-extra
ul.custom-list-extra {
    @extend .list-unstyled;
    li {
        position: relative;
        padding: 0 0 0 25px;
		line-height: 1.2;
        &::before {
			content: '\f00c';
            position: absolute;
            top: 0;
            left: 0;
			font-family: $font-awesome;
            font-weight: 900;
        }
		+ li {
            margin-top: 10px;
        }
    }
}

ul.footer-list {
    @extend .list-unstyled;
    margin: 0;
}

.betaal-list {
    @extend .list-unstyled;
    display: flex;
    margin: 0;
    column-gap: 4px;
    margin-left: 10px;

    @include media-breakpoint-down(lg) {
        margin-left: 0;
    }

    li {
        background-color: lighten($black, $amount: 20%);
        width: 30px;
        height: 26px;
        border-radius: 10px;
    }
}