// bg-dark
// =========================================================================
section.bg-dark {
	margin: 0;
	padding: 3rem 0;
}

// bg white
section.bg-white {
	background-color: $white;
}

// bg-green
// =========================================================================
section.bg-green {
	margin: 0;
	padding: 3rem 0;
	background-color: $green;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	li,
	span {
		color: $white;
	}
}

// intro-section
// =========================================================================
.intro-section {
	position: relative;
	padding: 3rem 0;
	background: $orange-light;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $orange;
	}

	// one
	.column.one {
		flex-direction: row;
		justify-content: flex-end;

		@include media-breakpoint-down(sm) {
			order: 1;
			justify-content: center;
		}

		@include media-breakpoint-only(md) {
			@include make-col(5);
		}

		@include media-breakpoint-up(lg) {
			@include make-col(6);
		}
	}

	// two
	.column.two {
		@include media-breakpoint-only(md) {
			@include make-col(7);
		}

		@include media-breakpoint-up(lg) {
			@include make-col(6);
		}

		@include media-breakpoint-down(sm) {
			order: 0;
		}
	}

	.highlight {
		background-color: $white;
		padding: 1.5rem 10px;
		display: flex;
		border-radius: 35px;
		margin-top: 1rem;
		margin-bottom: 3rem;

		@include media-breakpoint-up(md) {
			align-items: center;
			padding: 1.5rem 2.5rem;
		}

		h3 {
			padding-left: 20px;
			margin-bottom: 0;
			font-size: 1.1rem;

			@include media-breakpoint-up(lg) {
				font-size: 1.4rem;
			}
		}

		&::before {
			content: '';
			display: block;
			margin-left: 10px;
			background-image: url(/images/reward-icon.svg);
			background-repeat: no-repeat;
			background-size: 100%;
			width: 60px;
			flex: 0 0 60px;
			height: 85px;
		}

	}
}

// lead-section
// =========================================================================
.lead-section {
	position: relative;
	background: $red-light;
	padding-top: 110px;

	@include media-breakpoint-down(lg) {
		padding-top: 125px;
	}

	@include media-breakpoint-down(sm) {
		padding-top: 150px;
	}

	.container-one-column {
		padding-bottom: 1rem;
	}

	.container-two-columns {
		padding-bottom: 1rem;
		.container-holder {
			margin-bottom: 0;
			align-items: center;

			&>.column {
				margin-bottom: 0;
			}
		}
	}

	.page-title {
		margin-bottom: 1rem;
		text-align: center;
	}

	.page-block.title {
		text-align: center;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $red;
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		/* centering header title & subtitle */
		.title h1,
		.subtitle h5,
		.wysiwyg p {
			text-align: center
		}


		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}

			// .label {}
		}

		// assortiment
		.discount-price {
			.amount {
				font-size: $font-size-lg;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		.price {
			.amount {
				font-size: $font-size-lg;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		.former-price {
			text-decoration: line-through;

			.amount {
				font-size: $font-size-sm;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		// .downloads {
		// 	.label {}

		// 	.file {}
		// }
	}
}

&.service-category-detail {
	.lead-section {
		.info-nav {
			@extend .d-none;
		}
	}
}

&.service-detail {
	.lead-section {
		.info-nav {
			@extend .d-none;
		}
	}
}

// content-section
// =========================================================================
.content-section {
	padding: 3rem 0;

	.intro,
	.outro {
		max-width: 760px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 45px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 45px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	// margin-top: 3rem;
	background-color: $orange-light;
	padding: 3rem 0 4rem;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $orange;
	}

	.page-block.wysiwyg {
		p {
			font-size: 1.375rem;
		}
	}

	.owl-carousel {
		height: 60vh;
		min-height: 300px;
		max-height: 600px;

		.item {
			align-items: flex-end;
			height: 60vh !important;
			/* overide */
			min-height: 300px;
			max-height: 600px;
			padding: unset;
			/* overide */

			.owl-caption {
				z-index: 1;
				position: relative;
				margin: 0 auto;
				padding: 35px 15px 25px 15px;

				@include media-breakpoint-down(md) {
					border-radius: 10px;
					background: $primary;
				}

				@include media-breakpoint-up(lg) {
					max-width: 850px;
					min-height: 151px;
					background: url("/images/banner-caption-bg.svg") no-repeat;
				}

				.owl-title {
					font-size: 24px;
					font-weight: 700;
					text-shadow: none;
				}

				.owl-description {
					max-width: 540px;
					margin: 0 auto;

					a {
						color: $white;

						&:hover {
							color: red;
						}
					}

					p:last-of-type {
						margin-bottom: 0;
					}
				}

				.owl-btn {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 100%;
					width: 80px;
					height: 80px;
					margin: 0 auto -40px auto;
					padding: 0;
					border-radius: 100%;
					box-shadow: none !important;
					color: $primary;
					text-align: center;
					border: none;
					overflow: hidden;

					&:hover {
						background: $primary;
						color: $primary;
						box-shadow: 0 3px 6px rgba($black, 0.15) !important;
					}

					&::before {
						content: "\f144";
						font-family: "Font Awesome 5 Pro";
						color: #fff;
						font-size: 36px;
						font-weight: 900;
						line-height: 80px;
					}
				}
			}
		}

		.owl-dots {
			bottom: 0;

			.owl-dot {
				span {
					width: 16px;
					height: 16px;
					border-radius: 100%;
					border-color: $primary;
					box-shadow: none;
				}

				&:hover,
				&.active {
					span {
						background: $primary;
					}
				}
			}
		}
	}
}

// highlights-section
// =========================================================================
.highlights-section {
	padding: 3rem 0;

	.collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-down(xs) {
						@include make-col(6);
					}

					.card {
						background: none !important;
						text-decoration: none;

						.card-body {
							padding: 15px;

							@include media-breakpoint-up(xl) {
								padding: 15px;
							}

							.card-caption {
								display: flex;
								flex-direction: column;
								text-align: center;

								.card-title {
									color: $primary;
									font-size: 18px;
									transition: color 0.5s;
								}
							}
						}

						&[href]:hover {
							box-shadow: 0 0 6px rgba($black, 0.15);
						}
					}
				}
			}
		}
	}
}

// contact-section
// =========================================================================
.contact-section {
	padding: 3rem 0;

	.wysiwyg {
		max-width: 780px;
		margin: 0 auto;
		text-align: center;
	}

	.form {
		max-width: 480px;
		margin: 30px auto 0 auto;
		background: $green-light;
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 3rem 0;
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 3rem 0;
}


// thema's-section
// =========================================================================
.themas-section {
	background-color: $red-light;
	padding-top: 3rem;
	margin: 0 auto;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $red;
	}

	.owl-nav {
		display: none;
	}

	.owl-dots {
		.owl-dot {
			span {
				border-color: $red !important;
			}

			&:hover,
			&.active {
				span {
					background: $red !important;
					border-color: $red !important;
				}
			}
		}
	}

}


&.tickets-page {
	.page-block {
		h2 {
			margin: 2rem auto 4rem;
		}
	}

	.intro-section {
		h2 {
			margin: 1rem auto;
			// font-size: clamp([value-min], [value-preferred], [value-max]);
			font-size: clamp(2rem, 4vw, 2.5rem);

			&+p {
				font-size: 1.5rem;
			}

		}

		.btn {
			font-size: 1.5rem;
			padding: 6px 50px;
		}
	}

	.faq-section {
		margin: 0 auto;
		padding: 3rem 0;

		h2 {
			margin: 1rem auto;
			font-size: 2.5rem;

			&+p {
				a {
					color: $black;
					text-decoration: none;
				}
			}

		}
	}
}

// handel lead section with and without image
@include media-breakpoint-up(md) {
	.main {
		&>.container-one-column>.container-holder {
			&>.column.default:has(section + div) {
				flex-direction: row;
				align-items: center;
				padding-top: 110px;


				@include media-breakpoint-down(lg) {
					padding-top: 125px;
				}

				@include media-breakpoint-down(sm) {
					padding-top: 150px;
				}

				.lead-section,
				.resource-image {
					flex: 0 0 50%;
					max-width: 50%;
					padding-right: 15px;
					padding-left: 15px;
				}

				.lead-section {
					padding-top: 0;
					padding-right: 3rem;

					.container-one-column {
						padding: 0;
					}

					h1 {
						margin-bottom: 1.2rem;
					}
				}

				.resource-image {
					padding-left: 3rem;
				}
			}
		}
	}

	/* must checked on all pages! */
	.main {
		.lead-section {
			.container-holder {
				&:has(div.column.one, div.column.two) {
					flex-direction: row;
					div.column.one {
						max-width: 100%;
						flex: 0 0 100%;

						& + div.column.two:not(:has(div, section, p)) {
							display: none;
						}
					}

				}
			}
		}
 	}
}

/* 404 page */
&.error {
	.error-not-found-text,
	.error-internal-server-error-text {
		padding-top: 110px;

		@include media-breakpoint-down(lg) {
			padding-top: 125px;
		}

		@include media-breakpoint-down(sm) {
			padding-top: 150px;
		}
	}
}
