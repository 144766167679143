// theme setup
@import './0_config/_00-variables-default';
@import './0_config/00-mixins';
@import '~framework/assets/scss/V2/0_config/_00-util-bootstrap';
@import '~framework/assets/scss/V2/0_config/_10-variables-default';
@import '~framework/assets/scss/V2/0_config/_20-variables-bootstrap';
@import '~framework/assets/scss/V2/0_config/_30-variables-override';
@import './0_config/_10-variables-override';
@import '~framework/assets/scss/V2/1_vendor/bootstrap';

// force html font size (prevent tommybooking conflict)
html {
	@import '~framework/assets/scss/V2/5_utilities/reset';
	overflow-x: hidden;
}

// theme within body class
body.default {
	overflow-x: hidden;
	//@import '~framework/assets/scss/V2/2_layout/header';
	@import '~framework/assets/scss/V2/2_layout/containers';

	@import '~framework/assets/scss/V2/3_components/alert';
	@import '~framework/assets/scss/V2/3_components/buttons';
	@import '~framework/assets/scss/V2/3_components/cookie-consent';
	@import '~framework/assets/scss/V2/3_components/lists';
	@import '~framework/assets/scss/V2/3_components/cards';
	@import '~framework/assets/scss/V2/3_components/faq';
	@import '~framework/assets/scss/V2/3_components/form';
	@import '~framework/assets/scss/V2/3_components/recaptcha';
	@import '~framework/assets/scss/V2/3_components/loading';
	@import '~framework/assets/scss/V2/3_components/carousel';
	@import '~framework/assets/scss/V2/3_components/timeslot';

	@import '~framework/assets/scss/V2/4_page_block/gallery';
	@import '~framework/assets/scss/V2/4_page_block/collections';
	//@import '~framework/assets/scss/V2/4_page_block/logo';
	@import '~framework/assets/scss/V2/4_page_block/navbar-toggler';
	@import '~framework/assets/scss/V2/4_page_block/site-switcher';
	//@import '~framework/assets/scss/V2/4_page_block/menu';
	@import '~framework/assets/scss/V2/4_page_block/blog-filter';
	@import '~framework/assets/scss/V2/4_page_block/link';
	//@import '~framework/assets/scss/V2/4_page_block/notice-bar';

	@import '~framework/assets/scss/V2/5_utilities/additions';
	@import '~framework/assets/scss/V2/5_utilities/animations';
	@import '~framework/assets/scss/V2/5_utilities/darkmode';
	//@import '~framework/assets/scss/V2/5_utilities/sticky';

	// app styling
	@import './2_layout/base';
	@import './2_layout/eyecatcher';
	@import './2_layout/footer';
	@import './2_layout/header';
	@import './2_layout/sections';
	@import './2_layout/tickets-bar';

	@import './3_components/buttons';
	@import './3_components/faq';
	@import './3_components/cards';
	@import './3_components/lists';
	@import './3_components/icon-box';
	@import './3_components/footer-list';
	@import './3_components/review-boxs';

	@import './4_page_block/collections';
	@import './4_page_block/gallery';
	@import './4_page_block/notice-bar';
	@import './4_page_block/site-switcher';
	@import './4_page_block/plan-visit';

	@import './5_utilities/darkmode';

}
