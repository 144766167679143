.eyecatcher {
  min-height: $carousel-min_height;
  @extend .loading-overlay;

  &.large {
    min-height: $carousel-min_height--lg;
  }
}

.owl-carousel {
  min-height: $carousel-min_height;

  &:not(.slider) {
    @extend .loading-overlay;
  }

  .owl-stage {
    @extend .d-flex;

    .owl-item {
      flex: 0 0 auto;
    }
  }

  .item {
    @extend .d-flex;
    align-items: center;

    min-height: $carousel-min_height;
    @extend .h-100;
    padding: 7.5vh 0;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .owl-caption-holder {
      width: 100%;
    }

    .owl-container {
      @extend .container;
    }

    .owl-caption {
      text-align: center;
    }

    .owl-title {
      margin: 0 0 $headings-margin-bottom 0;
      font-family: $headings-font-family;
      font-size: $h2-font-size;
      font-weight: $headings-font-weight;
      line-height: 1.2;
      text-shadow: 2px 2px 2px rgba($body-color,.5);
      color: $white;
    }

    .owl-subtitle {
      margin: 0 0 $headings-margin-bottom 0;
      font-family: $headings-font-family;
      font-size: $h3-font-size;
      font-weight: $headings-font-weight;
      line-height: 1.2;
      text-shadow: 2px 2px 2px rgba($body-color,.5) ;
      color: $white;
    }

    .owl-description {
      margin: 0 0 $paragraph-margin-bottom 0;
      &, .list .list-item {
        color: $white;
      }
    }

    .owl-btn {
      @extend .btn;
      @extend .btn-primary;
      @extend .shadow;

      &:hover {
        @extend .btn-secondary;
      }
    }
  }

  .owl-nav {
    position: absolute;
    right: 0;
    bottom: 50%;
    left: 0;
    z-index: 100;
    margin: 0 0 25px 0;

    &.disabled {
      opacity: .25;
      pointer-events: none;
    }

    .owl-prev,
    .owl-next {
      position: absolute;

      display: block;
      width: 30px;
      height: 50px;
      outline: 0;

      font-size: $font-size-base * 2;
      line-height: 50px;
      @extend .text-center;
      text-shadow: 2px 2px 2px rgba($body-color,.5);
      color: $white;
      transition: $transition-base;

      &:hover {
        color: $primary;
      }
    }

    .owl-prev { left: 0; }
    .owl-next { right: 0; }

    &.disabled {
      @extend .d-none;
    }
  }

  .owl-dots {
    position: absolute;
    right: 0;
    bottom: 30px;
    left: 0;
    z-index: 100;
    height: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &.disabled {
      @extend .d-none;
    }

    .owl-dot {
      @extend .d-block;
      outline: 0;

      span {
        @extend .d-block;
        width: 15px;
        height: 15px;
        margin: 7.5px 2.5px;

        background: transparent;
        border: 2px solid $white;
        border-radius: 15px;
        box-shadow: 2px 2px 2px rgba($body-color,.5);
        transition: $transition-base;
      }

      &:hover, &:active {
        span {
          background: $white;
          border: 2px solid $white;
        }
      }
    }
  }

  // owl-carousel.large
  &.large {
    min-height: $carousel-min_height--lg;

    .item {
      min-height: $carousel-min_height--lg;
      padding: 15vh 0;
    }
  }

  // owl-carousel.slider
  &.slider {
    .item {
      @extend .align-items-start;

      min-height: 0;
      padding: 0;
    }

    .owl-nav {
      right: -30px;
      left: -30px;

      .owl-prev,
      .owl-next {
        text-shadow: none;
        color: $primary;

        &:hover {
          color: darken($primary, 15%);
        }
      }
    }

    .owl-dots {
      @extend .position-relative;
      bottom: 0;

      height: auto;
      margin-top: 15px;

      .owl-dot {
        span {
          background: transparent;
          border: 2px solid $primary;
          box-shadow: none;
        }

        &:hover, &.active {
          span {
            background: $primary;
            border: 2px solid $primary;
          }
        }
      }
    }
  }

  // owl-carousel.accommodation-gallery
  &.accommodation-gallery {

    &,
    .item {
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      min-height: unset;
      cursor: pointer;
      padding: 0;

      @include media-breakpoint-down(md) {
        aspect-ratio: 36 / 23;
        height: auto;
      }
    }

    .item {
      picture {
        display: block;
        height: 100%;
        
        img {
          height: 100%;
        }
      }
    }

    .owl-stage,
    .owl-stage-outer {
      height: 100%;
    }

    .owl-nav {
      left: 10px;
      right: 10px;

      .owl-next,
      .owl-prev {
        width: auto;
        font-size: 20px;
      }
    }
    
    .owl-dots {
      .owl-dot {
        span {
          box-shadow: none;
          border-width: 1px;
        }
        &:hover, &.active {
          span {
            background: $white;
          }
        }
      }
    }

    .num {
      background-color: #FFF;
      position: absolute;
      right: 10px;
      bottom: 10px;
      padding: 5px 15px;
      border-radius: 4px;
      z-index: 99;
      font-size: 12px;
      line-height: normal;
      font-weight: 700;
    }
  }
}
