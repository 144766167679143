.list {
  margin: 0;
  padding: 0;

  list-style: none;

  &.list-icons {
    margin: 10px 0 0 0;

    .list-item {
      @extend .position-relative;
      padding: 0 0 0 30px;

      .icon {
        @extend .position-absolute;
        left: 0;
      }
    }
  }
}

.vocabulary-term-list {
  @extend .d-flex;
  @extend .flex-wrap;
  @extend .list-unstyled;

  li {
    margin: 0 10px 0 0;
  }
}

.footer-logolink {
  .list {
    justify-content: center;
    @extend .d-flex;
    @extend .flex-wrap;

    .list-item {
      margin: 0 10px 0 0;
    }
  }
}

.footer-link {
  .list {
    @extend .d-block;
    @extend .d-sm-flex;
    @extend .flex-wrap;

    .list-item {
      margin: 0 10px 0 0;
    }
  }
}