// language switcher
// ==========================
.header {
    .site-switcher {
        margin-left: 1rem;
        .navbar-nav {
            &.language {
                border: 1px solid $black;
                border-radius: 12px;
                padding: 10px 12px;
            }

            .nav-item {
                .language-code {
                    text-transform: uppercase;
                    color: $black;
                    font-size: 18px;
                    line-height: 0;
                }

                img {
                    margin-left: 10px;
                    max-width: 22px;
                }

                .nav-link {
                    padding: 0 !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &.dropdown-toggle {
                        &::after {
                            display: none;
                        }
                    }
                }

                .dropdown-menu {
                    min-width: max-content;
                    border-radius: 15px;
                    &.show {
                        @include prefix(transform, translate3d(-34px, 24px, 0px) !important);
                    }
                }
            }
        }
    }
}