// Plan your visit block
// ===============================
.plan-your-visit {
    .tab-content {
        .tab-pane {
            justify-content: center !important;
            column-gap: 4rem;
            .text {
                margin-right: 0;
                h5 {
                    font-size: $h4-font-size;
                    text-transform: none;
                    margin-bottom: 0;
                }
                p {
                    margin: 0;
                    font-size: $h4-font-size;
                }
            }

            .link {
                .btn {
                    font-size: 1.7rem;
                    border-radius: 100px;
                    padding: 6px 30px;
                    text-transform: uppercase;
                }
            }
        }
    }
}