.card {
  @extend .h-100;
  background: $card-bg;
  box-shadow: none;
  overflow: hidden;

  .card-image {
    @extend .position-relative;

    flex: 0 0 auto;
    background: $gray-200;

    .card-image-link {
      @extend .d-block;
    }

    .card-img {
      border-radius: 0;
    }

    .card-image-caption {
      @extend .position-absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;

      display: block;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0;
      background: transparent;

      .card-image-label {
        padding: 5px 15px;
        background: rgba($black, .25);
        @extend .text-truncate;
        color: $white;
      }
    }
  }

  .card-body {
    @extend .d-flex;
    @extend .flex-column;
    padding: 15px;
    background: $card-bg;
  }

  .card-caption {
    flex: 1 1 auto;
    text-align: left;

    .card-title {
      margin: 0 0 $headings-margin-bottom 0;

      font-family: $headings-font-family;
      font-size: $font-size-base * 1.5;
      font-weight: $headings-font-weight;
      line-height: 1.2;
      text-transform: none;
      text-shadow: none;
      color: $body-color;
      transition: $transition-base;
    }

    .card-title-link {
      @extend .d-block;
      margin: 0 0 $headings-margin-bottom 0;
      text-decoration: none;
      color: $body-color;

      .card-title {
        margin: 0;
        color: inherit;
      }

      &:hover {
        text-decoration: none;
        color: $primary;
      }
    }

    .card-subtitle {
      margin: 0 0 $headings-margin-bottom 0;
      font-family: $headings-font-family;
      font-size: $font-size-base;
      line-height: 1.2;
      text-transform: none;
      text-shadow: none;
      color: $body-color;
    }

    .card-description {
      @extend .position-relative;
      margin: 0;
      overflow: hidden;

      font-family: $font-family-base;
      font-size: $font-size-base;
      font-style: $font-style-base;
      font-weight: $font-weight-base;
      line-height: $line-height-base;
      text-transform: none;
      text-shadow: none;
      color: $body-color;

      .list {
        .list-item {
          color: inherit;
        }
      }
    }
  }

  .card-buttons {
    flex: 0 0 auto;
    margin: 15px 0 0;
  }

  .card-btn {
    @extend .btn;

    background: $primary;
    border: $btn-border-width solid $primary;

    color: $white;

    &:hover {
      background: $black;
      border: $btn-border-width solid $black;

      color: $white;
    }
  }
}

// card > card-overlay
.card.card-overlay {
  .card-image {
    .card-image-caption {
      @extend .d-none;
    }
  }

  .card-img-overlay {
    display: block;
    flex-direction: column;
    padding: 15px;
    background: $card-bg-overlay;
    overflow: hidden;
  }

  .card-caption {
    padding: 0;
    background: transparent;

    .card-title {
      margin: 0 0 $headings-margin-bottom 0;

      text-shadow: 2px 2px 2px rgba($body-color, .5);
      color: $white;
    }

    .card-title-link {
      margin: 0 0 $headings-margin-bottom 0;
      text-decoration: none;
      color: $white;

      .card-title {
        color: inherit;
      }

      &:hover {
        text-decoration: none;
        color: $primary;
      }
    }

    .card-subtitle {
      margin: 0 0 $headings-margin-bottom 0;
      text-shadow: 2px 2px 2px rgba($body-color, .5);
      color: $white;
    }

    .card-description {
      @extend .d-none;
      @extend .d-md-block;
      min-height: 75px;
      max-height: 75px;
      margin: 0;

      text-shadow: 2px 2px 2px rgba($body-color, .5);
      color: $white;

      &::after {
        height: 50px;
        background: $card-bg-overlay;
        background: transparent;
      }
    }
  }

  .card-btn {
    background: transparent;
    border: $btn-border-width solid $white;

    color: $white;

    &:hover {
      background: $white;
      border: $btn-border-width solid $white;

      color: $body-color;
    }
  }
}

// card > card-assortiment
.card.card-assortiment {
  .card-caption {
    .card-prices {
      margin: 0;
      font-family: $font-family-base;
      font-size: $font-size-base;
      font-style: $font-style-base;
      font-weight: $font-weight-bold;
      line-height: $line-height-base;
      text-shadow: none;
      text-align: left;
      color: $body-color;

      .card-price {
        //@extend .text-truncate;

        span {
          display: inline-block;
        }
      }

      .card-price-message {
        //@extend .text-truncate;
      }
    }
  }
}

// card > card-arrangement
.card.card-arrangement {

  .card-image {
    overflow: visible;
    .card-label {
      position: absolute;
      right: 0;
      bottom: -10px;
      margin: 0 15px;
      background: $primary;
      padding: $btn-padding-y-lg $btn-padding-x-lg;
      border-radius: $border-radius;
      color: $white;
      font-weight: 700;
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 10px;
        width: 0;
        height: 0;
        border-top: 10px solid $primary;
        border-right: 10px solid transparent;
      }
    }
  }

  .card-info {
    margin-bottom: 1rem;

    .card-staydata {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 0.5rem 0;
      padding: 0;
      list-style: none;
      li {
        font-size: $font-size-base * 0.75;
        line-height: 1;
        + li {
          &::before {
            content: '|';
            margin: 0 5px;
          }
        }
      }
    }
    .card-traveldata {
      margin-bottom: 1rem;
      .dates {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 0.5rem 0;
        padding: 0;
        list-style: none;
        li {
          color: $primary;
          line-height: 1;
          font-size: $font-size-sm;
          + li {
            &::before {
              content: '\f061';
              margin: 0 7.5px;
              color: rgba($primary, 0.5);
              font-weight: 900;
              font-family: $font-awesome;
            }
          }
        }
      }
    }
    .card-prices {
      text-align: right;
      .card-old-price {
        font-size: $font-size-sm;
        line-height: 1;
        text-decoration: line-through;
      }
      .card-price {
        .card-price-prefix {
          font-size: $font-size-base * 0.75;
        }
        .card-price-amount {
          margin-left: 5px;
          color: $primary;
          font-size: $font-size-lg;
          font-weight: 700;
        }
      }
    }
  }

  .card-characteristics {
    margin: 0;
  }

  .card-buttons {
    margin: 0;
  }
}

// card > card-wide
.card.card-wide {
  @extend .flex-row;
  @extend .flex-wrap;

  .card-image {
    @include make-col(12);

    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(5);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(4);
    }
  }

  .card-body {
    @include make-col(12);

    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(7);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(8);
    }
  }
}

// card > card-icon
.card.icon {
  .card-image {
    background: $white;
    @extend .text-center;
  }
}

// parent behaviour
.masonry {
  .card {
    .card-caption {
      .card-description {
        min-height: 0;
        max-height: unset;

        &::after {
          @extend .d-none;
        }
      }
    }
  }
}

// parent behaviour
.inspiration {
  .card.icon {
    .card-image {
      @extend .h-100;

      .card-image-link {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;

        @extend .h-100;
      }
    }
  }
}
