.bg-dark {
  &, h1, h2, h3, h4, h5, h6, p { color: $body-color--dark; }

  ul, ol {
    li {
      color: $body-color--dark;
    }
  }

  ul.custom-list,
  ul.custom-list-extra {
    li::before {
      color: $body-color--dark;
    }
  }
}

.bg-dark {
  background-color: $dark !important;

  .alert-danger {
    background: transparent;
  }
}

.card.dark {
  background: $card-bg--dark;
  border: $card-border-width solid transparent;

  .card-image {
    background: $dark;
  }

  .card-body {
    background: $card-bg--dark;
  }

  .card-caption {
    .card-title {
      color: $white;
    }

    .card-title-link {
      color: $white;

      .card-title {
        margin: 0;
        color: inherit;
      }

      &:hover {
        color: $primary;
      }
    }

    .card-subtitle {
      color: $white;
    }

    .card-description {
      color: $white;

      &::after {
        background: $card-bg--dark;
        background: $card-bg-fade--dark;
      }
    }

    .card-prices, .card-price {
      color: $white;
    }
  }

  .card-info {
    .card-prices {
      .card-price-label,
      .card-old-price {
        color: $white;
      }

      .card-price {
        color: $primary;
      }
    }

    .card-traveldata {
      .card-arrival-label,
      .card-arrival-data,
      .card-duration-data {
        color: $white;
      }
    }
  }

  .card-btn {
    background: $white;
    border: $btn-border-width solid $white;

    color: $body-color;

    &:hover {
      background: $primary;
      border: $btn-border-width solid $primary;

      color: $white;
    }
  }
}

.bg-dark {
  .form {
    .form-control-label {
      color: $white;
    }

    .reCAPTCHA-text {
      color: $white;
    }
  }
}

.bg-dark {
  .owl-carousel {
    &.slider {
      .owl-nav {
        .owl-prev,
        .owl-next {
          color: $white;

          &:hover {
            color: $primary;
          }
        }
      }

      .owl-dots {
        .owl-dot {
          span {
            border: 2px solid $primary;
          }

          &:hover {
            span {
              background: $white;
              border: 2px solid $white;
            }
          }
          &.active {
            span {
              background: $primary;
              border: 2px solid $primary;
            }
          }
        }
      }
    }
  }
}
