.notice-bar {
	display: none;
	padding: 13px 15px;
	background: $secondary;
	color: $white;
	text-align: center;
	p {
		margin-bottom: 0;
	}
	a {
		color: $white;
		&:hover {
			color: red;
		}
	}
	&.show {
		display: block;
	}
	&-content {
		position: relative;
		padding-right: 24px;
	}
	&-close {
		position: absolute;
		top: 0;
		right: 0;
		width: 24px;
		height: 24px;
		text-align: center;
		&-icon {
			font-size: 20px;
			i {
				line-height: 24px;
			}
		}
		&-title {
			display: none;
		}
	}
}
