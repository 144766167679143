.menu-toggle {

  .navbar-toggler-icon {
    width: auto;
    height: auto;
    background-image: none;
  }
}

// behaviour
.header {
  .menu-toggle {

    @include media-breakpoint-up(xl) {
      min-width: auto;
    }
  }
}
