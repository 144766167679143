.link {
  a.link {
    @extend .btn;
    @extend .btn-primary;
    @extend .mt-3;
    @extend .mb-3;

    transition: $transition-base;
  }
}
