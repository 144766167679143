// btn
.btn {
	padding: 6px 20px;
	@include media-breakpoint-up(xxl) {
		padding-left: 35px;
		padding-right: 35px;
	}
	border-radius: 25px;
	font-size: 16px;
	font-weight: 700;

	/* big btn */
	&.btn-lg {
		font-size: 1.5rem;
	}

	/* btn-primary */
	&.btn-primary {
		&:hover {
		}
	}

	/* btn-outline-primary */
	&.btn-outline-primary {
		&:hover {
		}
	}

	/* btn-secondary */
	&.btn-secondary {
		&:hover {
		}
	}

	/* btn-outline-secondary */
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// btn-white
.btn-white {
	color: $primary;
	&:hover {
		color: $white;
	}
}

// btn-back
.btn-back {
	display: inline-block;
	font-size: 15px;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	&::before {
		display: inline-block;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

.btn-transparent {
	font-size: 2rem;
    font-weight: 700;

	&-green {
		color: $green;
	}

	&-primary {
		color: $primary;
	}

	&-secondary {
		color: $secondary;
	}
}