// footer
.footer {
	background: $orange-light;

	// footer-outro
	.footer-outro {
		position: relative;
		// margin: 4vh 0;
		padding: 3rem 0;

		// one
		.column.one {
			padding-right: 15px !important;
			@include media-breakpoint-down(sm) {
				order: 1;
			}

			@include media-breakpoint-only(md) {
				@include make-col(6);
			}

			@include media-breakpoint-up(lg) {
				@include make-col(8);
			}
		}

		// two
		.column.two {
			padding-left: 15px !important;
			@include media-breakpoint-down(sm) {
				order: 0;
				align-items: center;
			}

			@include media-breakpoint-only(md) {
				@include make-col(6);
			}

			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}

			.footer-white-box {
				background-color: $white;
				border-radius: 30px;
				padding: 30px 20px;
				text-align: center;
				display: flex;
				flex-direction: column;
				justify-content: space-around;

				@include media-breakpoint-up(lg) {
					height: 100%;
				}

				.logo {
					margin-top: 2.5rem;
				}

				p {
					font-size: 1rem;
				}
			}
		}

		// footer-text
		.footer-text {
			height: 100%;

			h3,
			h4,
			h5 {
				color: $orange;
			}

			p {
				margin-bottom: 1.2rem;
			}

			a.btn-secondary {
				font-size: 1.5rem;
				padding: 6px 40px;
				border-radius: 50px;
			}
		}

		.footer-social {
			display: flex;
			align-self: flex-end;
		}

		.betaalwijze {
			display: flex;
			align-items: center;
			font-size: 18px;

			@include media-breakpoint-down(lg) {
				order: 1;
				flex-direction: column;
				row-gap: 1rem;
				align-items: flex-start;
			}

			@include media-breakpoint-up(lg) {
				@include make-col(8);
			}
		}
	}

	// footer-sitelinks
	// .footer-sitelinks {
	// 	padding: 4vh 0 3vh 0;

	// 	.column {
	// 		@include media-breakpoint-up(xl) {
	// 			flex: 0 0 18.75%;
	// 			max-width: 18.75%;
	// 		}

	// 		&.five {
	// 			@include media-breakpoint-up(xl) {
	// 				flex: 0 0 25%;
	// 				max-width: 25%;
	// 			}
	// 		}
	// 	}

	// 	h5 {
	// 		font-weight: 700;
	// 	}

	// 	ul {
	// 		@extend .list-unstyled;
	// 	}

	// 	a:not(.btn) {
	// 		color: $body-color;
	// 		text-decoration: none;

	// 		&:hover {
	// 			color: $body-color;
	// 			text-decoration: underline;
	// 		}
	// 	}

	// 	// newsletter-box
	// 	.newsletter-box {
	// 		display: flex;
	// 		flex-direction: column;
	// 		justify-content: center;

	// 		@include media-breakpoint-down(xl) {
	// 			border-radius: 10px;
	// 			padding: 15px;
	// 		}

	// 		@include media-breakpoint-up(xl) {
	// 			max-width: 277px;
	// 			min-height: 232px;
	// 			margin-top: -20px;
	// 			padding: 20px;
	// 			background: url("/images/newsletter-box-bg.svg") no-repeat;
	// 			background-size: 100%;
	// 		}

	// 		text-align: center;
	// 	}
	// }

	// footer-socket
	.footer-socket {
		position: relative;
		// margin-top: 3vh;
		padding: 3vh 0 3vh 0;
		background: $green-light;

		// footer-logolink
		.footer-logolink {
			.list {
				.list-item {
					margin: 0 10px;
					font-size: 36px;

					.link {
						color: $black;

						&:hover {
							color: $primary;
						}
					}
				}
			}
		}

		// footer-link
		.footer-link {
			// margin-top: 20px;

			.list {
				text-align: center;
				justify-content: center;

				.list-item {
					margin: 10px;
					// font-size: 13px;

					.link {
						color: $black;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}
