.es-review-background-container,
.es-tabs-container {
    background-color: $white;
}

.es-load-more-button-container {
    .es-load-more-button {
        background-color: unset !important;
        border: none !important;
        & > span {

            background-color: $primary !important;
            border: 1px solid $primary !important;
            font-size: 1.5rem;
            padding: 6px 40px !important;
            border-radius: 50px;
            color: $white;
            @include prefix(transition, background-color .15s ease-in-out); // include from mixins

            &:hover {
                background-color: darken($primary, 10%) !important;
                border: 1px solid darken($primary, 10%) !important;
            }
        }
    }
}