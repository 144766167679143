// faq

.content-section {
	.column {
		& > .faq-category {
			margin: 30px 0 0;

			&:first-child {
				margin: 0;
			}
		}
	}
}

.faq-overview {
	max-width: 900px;
	padding: 15px 30px;
	background-color: $white;
	border-radius: 30px;
	margin: 0 auto;
	@include media-breakpoint-up(xl) {
		padding: 30px 60px;
	}


	.faq-category {
		margin-top: 0; /* override */
	}
	> .faq-category {
		h2 {
			text-align: center;
			margin: 0 auto 1.5rem;
			font-size: 2rem;
			color: $red;
		}
		.faq-items {
			.faq-item {
				border-bottom: 1px solid $red;
				&:last-of-type {
					border-bottom: none;
				}
				.faq-question {
					margin: 0;
					padding: 16px 0 5px;
					border: none;
					.faq-title {
						color: $black;
						padding: 0 30px 0 0;
						transition: color 0.5s;
					}
					.faq-icon {
						position: absolute;
						top: unset;
						bottom: 4px;
						left: auto;
						right: 0;
						width: 30px;
						line-height: 24px;
						text-align: center;
						font-size: 16px;
						color: $secondary;

						&:hover {
							color: darken($secondary, 20%);
						}
					}
					&:hover {
						.faq-icon {
							color: darken($secondary, 20%);
						}
						.faq-title {
							color: $black;
						}
					}
				}

				&.active {
					.faq-title {
						font-weight: 700;
					}
				}
				.faq-answer {
					margin: 0;
					padding: 0;
					border: none;
				}
			}
		}
	}
}

