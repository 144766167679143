// headings
h1,
h2,
h3,
h4,
h5,
h6 {
	text-transform: uppercase;
}

&.home h2 {
	font-size: $h1-font-size;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s !important;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// body-colors
&.w3-body-color-green {
	.lead-section {
		background: $green;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			color: $white;
		}
	}
}

&.w3-body-color-red {
	.lead-section {
		background: $red-light;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $red;
		}
	}
}

&.w3-body-color-orange {
	.lead-section {
		background: $orange-light;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $orange;
		}
	}
}

.custom-file-input {
	opacity: 1;
}

.collection.grid.four-columns-block {
	.grid-items {
		.item {
			// @include media-breakpoint-up(md) {
			// 	flex: 0 0 50%;
			// 	max-width: 50%;
			// }

			// @include media-breakpoint-up(xl) {
			// 	flex: 0 0 25%;
			// 	max-width: 25%;
			// }
		}
	}
}

// rounded images
.content-section {
	.page-block.resource-image {
		img {
			border-radius: 30px;
		}
	}
}