.site-switcher {
  .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

// behaviour
.header {
  .site-switcher {
    .navbar-nav.language {
      flex-direction: row;
      justify-content: flex-end;
    }

    @include media-breakpoint-up(xl) {
      min-width: auto;
      text-align: left;

      .navbar-nav.language {
        justify-content: flex-start;
      }
    }
  }
}
