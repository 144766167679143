.bg-dark {
	// btn
	.btn {
		/* btn-outline-primary */
		&.btn-outline-primary {
			border-color: $white;
			color: $white;
			&:hover {
			}
		}

		/* btn-secondary */
		&.btn-secondary {
			color: $white;
			&:hover {
			}
		}

		/* btn-outline-secondary */
		&.btn-outline-secondary {
			border-color: $white;
			color: $white;
			&:hover {
			}
		}
	}
}
