.header {
	z-index: 999;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: $transition-base;

	section {
		@include media-breakpoint-up(xl) {
			padding-left: 25px;
			padding-right: 25px;
		}
	}

	@include media-breakpoint-up(xl) {
		&.sticky {
			position: sticky;
		}
	}

	// header-top
	.header-top {
		background: $green-light;

		// @include media-breakpoint-down(lg) {
		// 	display: none;
		// }

		.column.default {
			@include media-breakpoint-down(lg) {
				flex-direction: row;
				justify-content: space-between;
			}
		}

		padding-top: 10px;
		padding-bottom: 10px;

		.column.default {
			@extend .align-items-center;
			@extend .navbar-expand-xl;
		}

		// menu
		.menu {
			justify-content: flex-end;
			flex-grow: 0;

			.navbar-nav {
				.nav-item {
					margin-left: 20px;
					font-size: 16px;
					line-height: 30px;

					.nav-link {
						padding: 0;
						color: $black;

						&:hover {
							color: $primary;
						}
					}
				}
			}
		}

		// quick-socials
		.quick-socials {
			margin: 0 0 0 auto;

			li {
				margin-right: 0;
				font-size: 22px;

				+ li {
					margin-left: 10px;
				}

				a {
					color: $black;

					&:hover {
						color: $pink;
					}

					span {
						display: none;
					}
				}
			}
		}

		// searchbox
		.searchbox {
			position: relative;
			z-index: 20;
			margin: 0 0 0 20px;
			overflow: hidden;
			min-width: 40px;

			@include media-breakpoint-down(lg) {
				flex-direction: row;
				justify-content: space-between;
			}

			.form-control {
				position: relative;
				// right: -100%;
				transition: $transition-base;

				width: 0;
				padding: 0;
				border: unset;
				&.show {
					// right: 0;
					width: 100%;
					padding: 0.375rem 0.75rem;
					border: 1px solid #ced4da;
				}
			}

			.search-icon {
				position: absolute;
				top: 0;
				// right: 0;
				left: 0;
				display: block;
				min-width: 34px;
				height: 34px;
				margin: 2px 15px 2px 0;
				line-height: 34px;
				text-align: center;
				transition: $transition-base;
				cursor: pointer;

				i {
					font-weight: 400;
				}

				&.hide {
					// right: -100%;
					left: 100%;
					margin: 2px 0 2px 15px;
				}

				&:hover {
					color: $pink;
				}
			}
		}


	}

	// header-main
	.header-main {
		background: $orange-light;

		.column.default {
			align-items: center;
			@extend .navbar-expand-xl;

			@include media-breakpoint-down(lg) {
				flex-flow: row wrap;
				justify-content: center;
			}
		}

		// logo
		.logo {
			z-index: 999;
			position: absolute;
			left: 0;
			max-width: 220px;
			transition: 0.5s;
			margin-top: 2rem;

			@include media-breakpoint-down(lg) {
				max-width: 170px;
				margin-top: 5.5rem;
			}

			@include media-breakpoint-down(lg) {
				margin-top: 8.5rem;
				max-width: 230px;
				left: 50%;
				@include prefix(transform, translateX(-50%)); // include from mixins
			}

			a {
				@extend .d-block;

				&:hover {
					transform: rotateZ(3deg);
				}
			}
		}

		// menu-toggle
		.menu-toggle {
			margin-right: auto;

			.navbar-toggler {
				display: flex;
				align-items: center;
				margin-left: -0.75rem;
				border: none;
				color: $primary;
				font-size: 18px;
				font-weight: 800;

				.navbar-toggler-icon {
					margin-right: 10px;
					font-size: 16px;
				}

				&[aria-expanded="true"] {
					.fa-bars {
						&::before {
							content: "\f00d";
						}
					}
				}
			}
		}

		// menu
		.menu {
			@include media-breakpoint-down(lg) {
				order: 4;

				.navbar-nav {
					>.nav-item {
						>.nav-link {
							color: $primary;
							font-size: 18px;
							font-weight: 800;
						}
					}

					.nav-item {
						&.active {
							>.nav-link {
								color: $primary;

								&.dropdown-item {
									background: transparent;
									color: $black;
								}
							}
						}

						.menu-icon {
							display: none;
						}

						&.active {
							.dropdown-menu {
								display: flex;
								flex-wrap: wrap;
								position: unset !important;
								transform: unset !important;
							}
						}

						.dropdown-menu {
							margin: 0 0 0 15px;
							padding: 0;
							border: none;
							background-color: unset;

							@include media-breakpoint-down(sm) {
								flex-direction: column;
							}

							&.show {
								display: flex;
								flex-wrap: wrap;
								position: unset !important;
								transform: unset !important;
							}

							.nav-item {
								.dropdown-item {
									font-weight: unset;
								}

								font-weight: 700;

								.dropdown-menu {
									display: block;
									margin: 0;
									padding: 0;
									border: none;

									.nav-item {
										font-weight: 400;
									}
								}
							}
						}
					}

					.nav-contentblock {
						display: none;
					}
				}
			}

			@include media-breakpoint-up(xl) {
				justify-content: flex-end;

				.navbar-nav {
					margin-left: 121px;

					/* ticket button */
					>.nav-item {
						position: static;
						margin: 0;
						padding: 0 15px;
						font-size: 20px;
						line-height: 40px;

						>.nav-link {
							z-index: 1;
							position: relative;
							padding: 0;
							color: $orange;
							font-weight: 800;

							&::after {
								display: none;
							}

							&:hover {
								color: rgba($primary, 0.5);
							}

							.menu-icon {
								z-index: -1;
								opacity: 0;
								transform: translateY(-20px);
								position: absolute;
								display: flex;
								align-items: center;
								justify-content: center;
								top: 15px;
								left: 0;
								right: 0;
								width: 80px;
								height: 80px;
								margin: 0 auto;
								background: #fff;
								border-radius: 100%;
								transition: 0.5s;
								overflow: hidden;

								&::after {
									content: "";
									z-index: -1;
									position: absolute;
									top: 40px;
									width: 80px;
									height: 40px;
									box-shadow: 0 4px 6px rgba($black, 0.1);
									border-bottom-left-radius: 80px;
									border-bottom-right-radius: 80px;
									transition: 0.5s;
								}

								img {
									width: 100%;
									height: auto;
								}
							}
						}

						&.show,
						&:hover {
							>.nav-link {
								.menu-icon {
									opacity: 1;
									overflow: visible;
									transform: translateY(0);
								}
							}
						}

						>.dropdown-menu {
							display: flex;
							opacity: 0;
							visibility: hidden;
							transform: scaleY(0);
							transform-origin: top;
							transition: 0.5s;
							z-index: unset;
							top: 100% !important;
							left: 0;
							right: 0;
							margin-left: -25px !important;
							margin-right: -25px !important;
							padding: 40px 25%;
							box-shadow: inset 0 3px 6px rgba($black, 0.15), 0 3px 6px rgba($black, 0.15);

							&.show {
								opacity: 1;
								visibility: visible;
								transform: scaleY(1) !important;
							}

							>.nav-item {
								>.nav-link {
									margin-bottom: 5px;
									font-weight: 700 !important;
									//pointer-events: none;
								}
							}
						}
					}

					.nav-item {

						/* level 1 */
						.dropdown-menu {
							margin: 0;
							border: none;
							border-radius: 0 0 30px 30px;

							.nav-item {
								margin-right: 30px;
								line-height: 1.1;

								.nav-link {
									padding: 0;
									color: $black;
									font-weight: 400;

									&:hover,
									&:focus {
										background: none;
										color: $pink;
									}
								}

								/* level 2 */
								.dropdown-menu {
									position: static;
									display: block;
									padding: 0;

									.nav-item {
										margin: 10px 0;
										line-height: 1.2;

										&.active {
											.nav-link {
												color: $primary;
											}
										}

										.nav-link {
											&:hover {
												color: $primary;
											}
										}
									}
								}
							}
						}
					}

					.nav-contentblock {
						margin: auto 0 0 auto;

						.menu-item-contentblock {
							&-link {
								display: flex;
								align-items: center;
								background-color: red;
								border-radius: 6px;
								color: $black;
								font-size: 14px;
								line-height: 1.25;
								text-decoration: none;
								overflow: hidden;

								&:hover {
									box-shadow: 0 0 6px rgba($black, 0.15);
								}
							}

							&-image {
								width: 100px;
							}

							&-content {
								max-width: 250px;
								padding: 10px 15px;

								&-title {
									margin-bottom: 5px;
									color: $white;
									font-size: 12px;
								}

								&-subtitle {
									font-weight: 700;
								}

								&-description {
									p {
										margin-bottom: 0;
									}
								}
							}
						}
					}
				}
			}
		}

		// tickets-button
		.tickets-button {
			position: relative;
			margin-left: 20px;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				right: 3px;
				transform: translateX(100%);
				height: 100%;
				width: 50vw;
				background-color: $pink;
				@include prefix(transition, background-color .5s); // include from mixins

				@include media-breakpoint-down(sm) {
					width: 50vw;
				}
			}

			ul {
				margin: 0;

				li {
					margin-right: 0;

					a {
						// position: relative;
						display: block;
						padding: 10px 3px 10px 25px;
						background-image: url('/images/tickets-button.svg');
						background-size: cover;
						color: $white;
						font-size: 24px;
						font-weight: 700;
						text-align: center;
						text-decoration: none;
						text-transform: uppercase;
						// @include prefix(transition, background-image .5s); // include from mixins
					}
				}
			}

			&:hover {
				&::after {
					background-color: darken($pink, $amount: 10%);
				}
				ul {
					li {
						a {
							background-image: url('/images/tickets-button-hover.svg');
						}
					}
				}
			}
		}

		// language
		.site-switcher {
			.navbar-nav {
				margin-left: 15px;
				text-transform: uppercase;

				.nav-item {
					.nav-link {
						padding: 0;
					}



					.dropdown-menu {
						min-width: auto;
						left: -7px !important;
						padding: 0;
						border-color: $white;
						border-radius: 10px;
						box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
						overflow: hidden;

						.dropdown-item {
							padding: 0.25rem;
						}
					}
				}
			}
		}
	}

	&.sticky {
		.header-main {
			.logo {
				@include media-breakpoint-up(xl) {
					//max-width: 150px;
					max-width: 200px;
					margin-top: 0;
				}
			}
		}
	}
}

&.navbar-collapse-active {
	.header-main {
		.logo {
			opacity: 0;
			visibility: hidden;
		}
	}
}
