// new colors
$green-light: #f2efda;
$green: #8c9970;

$orange-light: #ffe1bf;
$orange: #ffa350;

$red-light: #f4d4d0;
$red: #e55c5c;

$pink: #ef5b97;
$blue: #00b8e5;
$gray-light: #ddd;

// theme-colors
$primary: $pink;
$secondary: $blue;

// body
$body-bg: $green-light;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=Source+Sans+3:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$font-family-primary: 'Source Sans 3', sans-serif;
$font-family-secondary: 'Lilita One', sans-serif;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2.25; // 36px
$h2-font-size: $font-size-base * 1.875; // 30px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.375; // 22px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-color: $green;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: false;
