.add-arrow {
  &--before {
    &::before {
      margin-right: 10px;

      font-family: 'Font Awesome 5 Pro';
      font-weight: $font-weight-bold;
      content: '\f104';
    }

    &-r {
      &::before {
        content: '\f104';
      }
    }
  }

  &--after {
    &::after {
      margin-left: 10px;

      font-family: 'Font Awesome 5 Pro';
      font-weight: $font-weight-bold;
      content: '\f105';
    }

    &-r {
      &::after {
        content: '\f105';
      }
    }
  }

  &--none {
    &::before,
    &::after {
      @extend .d-none;
    }
  }
}
