// default
.card {
	border: none;
	overflow: hidden;
	background-color: $white;
	border-radius: 30px;
	.card-image {
		overflow: hidden;
		.card-image-link {
			@extend .d-block;
			.card-img {
				transition: 0.5s ease-in-out;
			}
			&:hover {
				.card-img {
					transform: scale(1.03);
				}
			}
		}
	}
	.card-body {
		@include media-breakpoint-up(xl) {
			padding: 30px;
		}
		.card-caption {
			display: flex;
			flex-direction: column;
			.card-title-link {
				margin-bottom: 10px;
				color: $green-light;
				&:hover {
					.card-title {
						color: $body-color;
					}
				}
			}
			.card-title {
				color: $red;
				text-align: left;
				font-size: 24px;
				font-weight: 500;
				text-transform: uppercase;
				word-wrap: normal;

				&:hover {
					color: darken($red, 10%);
				}
			}
			.card-subtitle {
				font-family: $font-family-primary;
				color: $green;
				font-size: 16px;
				font-weight: 700;
			}
			.card-description {
				color: $black;
				&:after {
					@extend .d-none;
				}
			}
			.card-price {
				color: $green;
				.prefix {
					font-size: 20px;
				}
				.euro {
					font-size: 20px;
				}
				.price {
					font-family: $font-family-secondary;
					font-size: 2rem;
				}
			}
		}
		.card-buttons {
			text-align: left;
			margin-top: 0;
			margin-bottom: 1rem;
			margin-top: 1rem;
			.card-btn {
				@extend .btn-secondary;
				font-size: 1.5rem;
				&:hover {
					background-color: darken($secondary, 10%);
					border-color: darken($secondary, 10%);
				}
			}
		}
	}

	// card-ticket
	&.ticket,
	&.card-ticket {
		.card-body {
			background-color: $white;
			background-image: linear-gradient($white, $white 96%, $green-light 96%, $green-light);
			padding: 0px 0px 70px;
			&::after {
				content: '';
				width: 100%;
				height: 50px;
				background-color: $green-light;
				background-image: url('/images/card-bottom.svg');
				background-repeat: no-repeat;
				background-position: bottom;
				background-size: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
			}

			.card-caption {

				.card-title-link {
					// background-color: $green;
					background-image: linear-gradient($green-light, $green-light 10%, $green 10%, $green);
					padding: 60px 30px 30px;
					position: relative;
					margin-bottom: 0;

					&::before {
						content: '';
						width: 100%;
						height: 34px;
						background-color: $green-light;
						background-image: url('/images/card-top.svg');
						background-repeat: no-repeat;
						background-size: 100%;
						position: absolute;
						border-bottom: $green;
						top: 1px;
						left: 0;

						@include media-breakpoint-down(sm) {
							height: 31px;
						}
					}

					&::after {
						content: '';
						position: absolute;
						bottom: -2px;
						left: 0;
						background-size: 44px 6px;
						background-image: linear-gradient(to right, $green-light 50%, $green 0%);
						width: 100vw;
						height: 4px;
						background-position: bottom;
						background-repeat: repeat-x;
					}

					.card-title {
						color: $green-light;
						text-align: center;
					}
				}
			}

			.card-description {
				font-size: 1.2rem;
				padding: 40px;

				&::before {
					content: '';
					position: absolute;
					top: 2px;
					left: 0;
					background-size: 44px 6px;
					background-image: linear-gradient(to right, $green-light 50%, $white 0%);
					width: 100vw;
					height: 4px;
					background-position: top;
					background-repeat: repeat-x;
				}
			}

			.card-price {
				margin-top: auto;
				text-transform: uppercase;
				padding: 0 40px;
			}
			.card-subtitle {
				font-size: 1.2rem;
				font-family: inherit;
				padding: 0 40px;
				color: $black;
			}
		}

		.card-buttons {
			padding: 0 30px;
			margin-bottom: 0;

			.card-btn {
				max-width: 80%;
				display: block;
				text-align: center;
				margin: 1rem auto 0;
				background-color: $primary;
				border-color: $primary;
				&:hover {
					background-color: darken($primary, $amount: 10%);
					border-color: darken($primary, $amount: 10%);
				}
			}
		}
	}
}


// highlight
.highlight-item {
	.card {
		background: $green-light;
	}
}

// break lines for first card of tickets
.main {
	section.content-section {
		.grid-items {
			.item:nth-child(1) {
				.card-ticket {
					.card-title-link {
						padding: 60px 68px 30px;
					}
				} 
			}
		}
	}
}

// search result page cards
&.search {

	.searchbox {
		margin-bottom: 1rem;
		.search-icon {
			display: none;
		}
	}
	.search-results {
		.item {
			margin-top: 1.5rem;
		}
	}
} 