// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh !important; // overide
	min-height: 280px !important; // overide
	max-height: 540px;
	padding: unset; // overide
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: calc(100vh - 146px) !important; // overide
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: calc(100vh - 146px) !important; // overide
	}
}

// eyecatcher
.eyecatcher {
	background: $orange-light;
	min-height: unset; /* overide */
	overflow: hidden;

	// vimeo-video
	.vimeo-video {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				/* height = 100 * (9 / 16) = 56.25 */
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				/* width = 100 / (9 / 16) = 177.777777 */
				width: 177.78vh;
			}
		}
	}

	&::after {
		content: url('/images/eyecatcher-bg.svg');
		z-index: 100;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto -1px auto;
		font-size: 0;
		line-height: 0;
		aspect-ratio: 300/23;
	}

	// owl-carousel
	.owl-carousel {
		.item {
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba($black, 0.2);
			}

			.owl-caption {
				position: relative;
				max-width: 100%;
				margin: 0 auto;
				color: $white;
				text-align: center;

				.owl-title {
					margin-bottom: 0;
					font-size: 35px;
					line-height: 1.2;
					text-shadow: none;
					@include media-breakpoint-up(xl) {
						font-size: 60px;
					}
				}

				.owl-subtitle {
					font-size: 32px;
					text-shadow: none;
				}

				.owl-description {
					font-size: 18px;
					text-shadow: none;
				}
			}
		}

		.owl-dots,
		.owl-nav {
			@extend .d-none;
		}
	}

	/*
	h1 {
		z-index: 100;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
		padding: 15px;
		color: $white;
		font-size: 50px;
		font-weight: 400;
		&::after {
			display: none;
		}
		@include media-breakpoint-up(xl) {
			font-size: 60px;
		}
	}
	*/
}
