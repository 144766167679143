.icon-box-holder {
    display: flex;
    column-gap: 30px;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
        flex-direction: column;
        row-gap: 1rem;
    }
    .icon-box {
        flex: 1 1 auto;
        // color: $white;
        padding: 12px 20px !important;
        border-radius: 20px !important;
        font-size: 18px !important;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        text-decoration: none;

        @include media-breakpoint-down(lg) {
            padding: 15px !important;
            font-size: 16px !important;
        }
    
        i {
            margin-right: 1.2rem;
            font-size: 30px !important;
            font-weight: 300;
            vertical-align: middle;

            @include media-breakpoint-down(lg) {
              margin-right: 10px;
            }
        }

    }
}

.footer-social {
    .list {
        justify-content: flex-start;
        display: flex;
        .list-item {
            border-radius: 20px;
            margin: 0 15px 0 0;
            background-color: $secondary;
            @include prefix(transition, background-color .15s ease-in-out); // include from mixins

            @include media-breakpoint-down(sm) {
                margin: 0 10px 0 0;
            }

            &:hover {
                background-color: darken($secondary, $amount: 10%);
            }
            &:last-of-type {
                margin: 0;
            }
            a {
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $white;
                text-decoration: none;
                font-size: 2rem;

            }
        }
    }
}