.collection {

	// grid
	&.grid {
		.grid-items {
			.item {
				@include media-breakpoint-up(xl) {
					@include make-col(4);
				}
			}
		}
	}

	// slider
	&.slider {
		.owl-item {
			.card {
				.card-body {
					@include media-breakpoint-up(xl) {
						padding: 20px;
					}
				}
			}
		}

		.owl-dots {
			margin-top: 26px;
			.owl-dot {
				span {
					width: 30px;
					height: 30px;
					border-width: 4px;
					margin: 0 10px;
				}
			}
		}

		.owl-nav {
			right: -70px;
			left: -70px;
		}
	}

	// circle slides slider
	&.circle-slides-slider {
		max-width: 80vw;
		margin: auto;
		height: max-content;
		margin-top: 2rem;

		@include media-breakpoint-down(lg) {
			max-width: 90vw;
		}

		.owl-stage-outer {
			padding-top: 1rem;
		}

		.owl-item {
			.card {
				background-color: unset;
			}
			
			&.active {
				.card {
					overflow: visible;
				}
			}

			.item {
				height: unset !important;
				min-height: unset;
				max-height: unset;
			}
			
			.card-image {
				// --mask: url("data:image/svg+xml,%3Csvg width='200' height='200' viewBox='0 0 200 200' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M199.178 99.589C199.178 165.982 165.982 199.179 99.5893 199.179C33.1963 199.179 0 165.982 0 99.589C0 33.1964 33.1963 0 99.5893 0C165.982 0 199.178 33.1964 199.178 99.589Z' fill='black'/%3E%3C/svg%3E") 0 0 / 100% 100% no-repeat;
				// @include prefix(mask, var(--mask)); // include from mixins
				// @include prefix(transform, scale(1)); // include from mixins
				// @include prefix(transition, transform .7s ease-in-out); // include from mixins

				// @include media-breakpoint-only(lg) {
				// 	@include prefix(transform, scale(.8)); // include from mixins
				// 	@include prefix(transition, transform .7s ease-in-out); // include from mixins
				// }

				border-radius: 50%;
	
				img {
					object-fit: cover;
					object-position: center;
					aspect-ratio: 1;
				}
			}

			.card-body {
				padding: 20px 0 0 !important;
				flex: 0 1 auto;
				transition: margin .7s ease-in-out;
				.card-title {
					color: $orange;
					text-align: center;
					margin: auto auto .5rem;
				}
	
				.card-buttons {
					display: none;
				}
			}
		}

		.owl-nav {
			right: 37%;
			left: 37%;
			margin: 0;
			bottom: 2.9rem;
			z-index: 999;

			@include media-breakpoint-down(md) {
				right: 30%;
				left: 30%;
				bottom: 2.6rem;
			}

			@include media-breakpoint-down(sm) {
				right: 0;
				left: 0;
				bottom: 7.5rem;
			}

			@include media-breakpoint-only(lg) {
				bottom: 2.7rem;
				right: 32%;
				left: 32%;
			}

			svg {
				width: 23px;
			}
		}

		.owl-dots {
			.owl-dot {
				span {
					border-color: $orange;
				}
				&.active,
				&:hover {
					span {
						background-color: $orange;
						border-color: $orange;
					}
				}
			}
		}

	}
}
