$font-awesome: "Font Awesome 5 Pro" !default;

// build options
$use-sticky-header: false !default;
$use-html-fontsize-reset: true !default;

// colors
$primary: #BBD331 !default;
$secondary: #6C757D !default;
$tertiary: #ADB5BD !default;
$success: #BBD331 !default;
$info: #17A2B8 !default;
$warning: #FFC107 !default;
$danger: #DC3545 !default;
$light: #F8F9FA !default;
$dark: #343A40 !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "success": $success,
  "info":	$info,
  "warning": $warning,
  "danger":	$danger,
  "light":	$light,
  "dark":	$dark,
);

$body-bg: #FFF !default;
$body-color: #000 !default;
$body-color--dark: #fff !default;
$link-color: $primary !default;
$link-decoration: underline !default;
$link-color--hover: darken($link-color, 15%) !default;

// font
$font-family-base: 'Helvetica', sans-serif !default;

// typography
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-style-italic: italic;
$font-style-base: normal !default;

$headings-font-family: $font-family-base !default;
$headings-font-style: $font-style-base !default;
$headings-font-weight: $font-weight-bold !default;
$headings-color: $body-color !default;

$color-h1: $body-color !default;
$color-h2: $body-color !default;
$color-h3: $body-color !default;
$color-h4: $body-color !default;
$color-h5: $body-color !default;
$color-h6: $body-color !default;

// grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;

$grid-breakpoints-small: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths-small: (
  xs: 0,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1000px,
  xxl: 1300px,
  xxxl: 1600px
) !default;

// header
$header-logo-height: 50px !default;
$header-logo-height--lg: 80px !default;
$header-logo-margin: 20px !default;

// card
$card-bg--dark: #000 !default;

// carousel
$carousel-min_height: 35vh !default;
$carousel-min_height--lg: 70vh !default;
